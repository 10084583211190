import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageCropperModule } from 'ngx-img-cropper';
import { MaterialModule } from '../material.module';


@NgModule({
  declarations: [ImageDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ImageCropperModule,
    MatDialogModule,
  ]
})
export class DialogsModule { }
