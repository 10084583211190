import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers';
import { finalize, first, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { currentAuth } from '../auth/auth.selectors';
import { loadCurrentUser } from './user.actions';

@Injectable()
export class UserResolver implements Resolve<any> {
    loading = false;

    constructor(private store: Store<AppState>) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.store.pipe(
            tap(() => {
                if (!this.loading) {
                    this.loading = true;
                    const auth = this.store.pipe(select(currentAuth));
                    auth.subscribe(
                        data => {
                            if (data !== undefined) {
                                this.store.dispatch(loadCurrentUser(
                                    {id: data.uid}
                                ));
                            }
                        }
                    );
                }
            }),
            first(),
            finalize(() => this.loading = false)
        );
    }
}
