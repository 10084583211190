import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppState } from 'src/app/state/reducers';
import { Store } from '@ngrx/store';
import { currentTemplate } from 'src/app/state/presentation/presentation.selector';
import { Template } from 'src/app/state/templates/template.model';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Update } from '@ngrx/entity';
import { updateTemplate, createTemplate } from 'src/app/state/templates/template.actions';
import { Location } from '@angular/common';
import { TemplateLocation } from 'src/app/state/templateLocations/templateLocation.model';
import { selectAllTemplateLocations } from 'src/app/state/templateLocations/templateLocation.selectors';
import { TemplateLocationEditorComponent } from '../template-location-editor/template-location-editor.component';
import { deleteLocation } from 'src/app/state/templateLocations/templateLocation.actions';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent implements OnInit, OnDestroy {
  $template: Observable<Template>;
  $locations: Observable<TemplateLocation[]>;

  @ViewChild(TemplateLocationEditorComponent, {static: false}) locationEditor: TemplateLocationEditorComponent;

  template: Template;
  templateSubscription: Subscription;
  form: FormGroup;
  isNew = true;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private routerLocation: Location,
    ) {

  }

  ngOnInit() {
    const formControls = {
      name: ['', Validators.required],
      category: ['', Validators.required],
      description: ['', Validators.required],
      published: ['', []],
      enableWebsite: ['', []],
      enablePriceRange: ['', []],
      enableEntranceFee: ['', []],
      enableKitchen: ['', []],
      enablePicture: ['', []],
      browsable: ['', []],
    };

    this.$template = this.store.select(currentTemplate);
    this.templateSubscription = this.$template.subscribe(
      data => {
        this.form = this.fb.group(formControls);
        if (data !== undefined) {
          this.form.patchValue({...data});
          // we're editing an exiting template
          this.isNew = false;
          this.template = data;
        } else {
          this.isNew = true;
        }
      }
    );

    this.$locations = this.store.select(selectAllTemplateLocations);
  }

  ngOnDestroy(): void {
    this.templateSubscription.unsubscribe();
  }

  save(): void {
    if (this.isNew) {
      this.createNew();
    } else {
      this.update();
    }
  }

  createNew(): void {
    const template: Template = {
      ...this.form.value
    };

    this.store.dispatch(createTemplate({template}));
  }

  update(): void {
    const template: Template = {
      ...this.template,
      ...this.form.value
    };

    const update: Update<Template> = {
      id: template.id,
      changes: template
    };

    this.store.dispatch(updateTemplate({update}));

    this.locationEditor.updateTemplate(template);
  }

  close(): void {
    this.routerLocation.back();
  }

  selectLocation(location: TemplateLocation) {
    this.locationEditor.setLocation(location);
  }

  deleteLocation(locationID: string) {
    this.store.dispatch(deleteLocation(
      {templateID: this.template.id, locationID }
    ));
  }
}
