import { createAction, props } from '@ngrx/store';
import { User } from './user.model';
import { Update } from '@ngrx/entity';

export const loadCurrentUser = createAction(
    '[Auth Effect] load Current User',
    props<{id: string}>()
);

export const currentUserLoaded = createAction(
    '[User Effect] current user loaded',
    props<{user: User}>()
);

export const currentUserLoadFailed = createAction(
    '[User Effect] current user load failed',
    props<{error: string}>()
);

export const updateCurrentUserLocation = createAction(
    '[Location Editor] update user last lat, long, zoom',
    props<{
        update: Update<User>
    }>()
);

export const updateCurrentUserSuccess = createAction(
    '[User Effect] Update User Success'
);






