import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { tap, first, finalize } from 'rxjs/operators';
import { loadAllTemplates } from './template.actions';

@Injectable()
export class TemplateResolver implements Resolve<any> {
    loading = false;

    constructor(private store: Store<AppState>) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.store.pipe(
            tap(() => {
                if (!this.loading) {
                    this.loading = true;
                    this.store.dispatch(loadAllTemplates());
                }
            }),
            first(),
            finalize(() => this.loading = false)
        );
    }

}
