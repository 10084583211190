import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TemplateActions } from './action-types';
import { concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Template } from './template.model';
import { of } from 'rxjs';

@Injectable()
export class TemplateEffects {

    loadTemplates$ = createEffect(() => this.actions$.pipe(
            ofType(TemplateActions.loadAllTemplates),
            concatMap(action => this.db.collection<Template>('templates').stateChanges()),
            mergeMap(actions => actions),
            map(action => {
                switch (action.type) {
                    case 'added': {
                        const data = action.payload.doc.data() as Template;
                        data.id = action.payload.doc.id;
                        return TemplateActions.added( {
                            template: data
                        });
                    }

                    case 'removed':
                        return TemplateActions.removed( {id: action.payload.doc.id });

                    case 'modified': {
                        const data = action.payload.doc.data() as Template;
                        data.id = action.payload.doc.id;

                        return TemplateActions.modified( {
                            id: action.payload.doc.id,
                            template: data
                        });
                    }
                }
            })
        )
    );

    updateTemplate$ = createEffect(() => this.actions$.pipe(
        ofType(TemplateActions.updateTemplate),
        switchMap(data => {
            const ref = this.db.collection('templates').doc<Template>(data.update.id.toString());
            return of(ref.update(data.update.changes));
        }),
        map(() => TemplateActions.updateTemplateSuccess())
    ));

    createTemplate$ = createEffect(() => this.actions$.pipe(
        ofType(TemplateActions.createTemplate),
        switchMap(data => {
            const ref = this.db.collection('templates');
            return of(ref.add(data.template));
        }),
        map(() => TemplateActions.createTemplateSuccess())
    ));

    deleteTemplate$ = createEffect(() => this.actions$.pipe(
        ofType(TemplateActions.deleteTemplate),
        switchMap(data => {
            const ref = this.db.collection('templates').doc(data.templateID);
            return of(ref.delete());
        }),
        map(() => TemplateActions.deleteTemplateSuccess())
    ));

    constructor(
        private actions$: Actions,
        private db: AngularFirestore
    ) {
    }
}
