import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';
import { reducers } from './reducers';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AuthEffects } from './auth';
import { AuthGuard } from './auth/auth.guard';
import { TemplateResolver } from './templates/template.resolver';
import { TemplateEffects } from './templates/template.effects';
import { TemplateLocationResolver } from './templateLocations/templateLocation.resolver';
import { TemplateLocationEffects } from './templateLocations/templateLocation.effects';
import { TemplateGuard } from './templates/template.guard';
import { UserEffects } from './user/user.effects';
import { UserResolver } from './user/user.resolver';
export const firebaseConfig = environment.firebaseConfig;

@NgModule({
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,

    EffectsModule.forRoot([
      AuthEffects,
      TemplateEffects,
      TemplateLocationEffects,
      UserEffects,
    ]),

    // Signature matches AppState interface
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictActionSerializability: false, // can't save firestore dates when this is true
          strictStateSerializability: false,
      }
    }),

    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),

    StoreRouterConnectingModule.forRoot({
        stateKey: 'router',
        routerState: RouterState.Minimal
    }),


  ],
  exports: [
      StoreModule,
      StoreDevtoolsModule,
      StoreRouterConnectingModule,
      EffectsModule,
  ],
  providers: [
    AuthGuard,
    TemplateGuard,
    TemplateResolver,
    TemplateLocationResolver,
    UserResolver,
  ],
})
export class AppStateModule {}

