import { Component, OnInit, Input, NgZone, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TemplateLocation } from 'src/app/state/templateLocations/templateLocation.model';
import { createLocation, updateLocation } from 'src/app/state/templateLocations/templateLocation.actions';
import { Update } from '@ngrx/entity';
import { Template } from 'src/app/state/templates/template.model';
import { PriceRanges, Kitchens } from 'src/app/utils/arrays';
import { Marker, LngLat, MapMouseEvent } from 'mapbox-gl';
import { currentUser, currentUserID } from 'src/app/state/user/user.selectors';
import { map, first, tap, skipWhile, finalize } from 'rxjs/operators';
import { User } from 'src/app/state/user/user.model';
import { updateCurrentUserLocation } from 'src/app/state/user/user.actions';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/app/dialogs/image-dialog/image-dialog.component';
import * as firebase from 'firebase';

@Component({
  selector: 'app-template-location-editor',
  templateUrl: './template-location-editor.component.html',
  styleUrls: ['./template-location-editor.component.scss']
})
export class TemplateLocationEditorComponent implements OnInit, OnDestroy {
  @Input()
  template: Template;

  form: FormGroup;
  isNew = true;
  location: TemplateLocation;

  priceRanges = PriceRanges;
  kitchens = Kitchens;

  mapCenter = [0, 0];
  markerPos = [0, 0];
  zoomLevel: [number] = [15];
  newZoomLevel: number;
  newCenter: LngLat;

  pictureSize: number;
  pictureRef: string;
  pictureUri: string;

  filedata: any;
  imageChanged = false;
  uploadTask: AngularFireUploadTask;
  uploadStatus: Observable<number>;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
  ) {
    this.filedata = {};
  }

  ngOnInit() {
    this.setLastUserLocation(true);
    this.setFormControls();
  }

  ngOnDestroy() {
    this.store.pipe(
      select(currentUserID),
      map(id => {
        const user: Partial<User> = {
          lastLng: +this.mapCenter[1].toFixed(6),
          lastLat: +this.mapCenter[0].toFixed(6),
          lastZoom: this.newZoomLevel === undefined ? this.zoomLevel[0] : this.newZoomLevel,
        };

        const update: Update<User> = {
          id,
          changes: user
        };

        this.store.dispatch(updateCurrentUserLocation(
          {update}
        ));
      }),
      first(),
    ).subscribe();
  }

  setLocation(location: TemplateLocation | undefined) {
    if (location === undefined) {
      this.isNew = true;
      this.form.reset();
      this.location = undefined;
      this.setLastUserLocation(false);
      this.pictureRef = '';
      this.pictureSize = 0;
      this.pictureUri = '';
      this.filedata = {};
    } else {
      this.isNew = false;
      this.form.reset();
      this.form.patchValue({...location});
      this.location = location;
      this.pictureRef = this.location.pictureRef;
      this.pictureSize = this.location.pictureSize;
      this.pictureUri = this.location.pictureUri;

      if (this.location.point !== undefined) {
        this.mapCenter = [parseFloat((+this.location.point.longitude).toFixed(6)), parseFloat((+this.location.point.latitude).toFixed(6))];
        this.markerPos = this.mapCenter;
      } else {
        this.setLastUserLocation(false);
      }
    }
    this.imageChanged = false;
  }

  setLastUserLocation(includeZoomLevel: boolean) {
    this.store.pipe(
      select(currentUser),
      skipWhile(u => !u),
      map(user => {
        if (user !== undefined) {
          this.mapCenter = [user.lastLng, user.lastLat];
          this.markerPos = this.mapCenter;
          if (includeZoomLevel) {
            this.zoomLevel = [user.lastZoom];
          }
        }
      }),
      first(),
    ).subscribe();
  }

  updateTemplate(template: Template) {
    this.template = template;
    this.setFormControls();
  }

  setFormControls() {
    const formControls = {
      name: ['', Validators.required],
      description: ['', []],
      website: ['', this.template.enableWebsite
      ? [
        Validators.required,
        Validators.pattern('https?://?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
      ] : ''],
      priceRange: ['', this.template.enablePriceRange ? Validators.required : 0],
      entranceFee: ['', this.template.enableEntranceFee ? Validators.required : false],
      kitchen: ['', this.template.enableKitchen ? Validators.required : ''],
    };
    this.form = this.fb.group(formControls);
  }

  async createNew() {
    await this.saveImageIfNeeded();
    const marker = {
      point: new firebase.firestore.GeoPoint(+(this.markerPos[1]).toFixed(6), +(this.markerPos[0]).toFixed(6))
    };

    let location: TemplateLocation = {
      ...this.form.value,
      ...marker,
    };

    if (this.template.enablePicture) {
      const imageInfo = {
        pictureRef: this.pictureRef,
        pictureSize: this.pictureSize,
        pictureUri: this.pictureUri,
      };

      location = {
        ...location,
        ...imageInfo
      };
    }

    this.store.dispatch(createLocation(
      {templateID: this.template.id, location}
    ));

    this.setLocation(undefined);

  }

  async update() {
    await this.saveImageIfNeeded();
    const marker = {
      point: new firebase.firestore.GeoPoint(+(this.markerPos[1]).toFixed(6), +(this.markerPos[0]).toFixed(6))
    };

    console.log(this.form.value);

    let location: TemplateLocation = {
      ...this.location,
      ...this.form.value,
      ...marker,
    };

    if (this.template.enablePicture) {
      const imageInfo = {
        pictureRef: this.pictureRef,
        pictureSize: this.pictureSize,
        pictureUri: this.pictureUri,
      };

      location = {
        ...location,
        ...imageInfo
      };
    }

    const update: Update<TemplateLocation> = {
      id: location.id,
      changes: location
    };

    this.store.dispatch(updateLocation(
      {templateID: this.template.id, update }
    ));
  }

  async saveImageIfNeeded() {
    if (this.imageChanged) {
      // remove the old picture, if any
      await this.deleteCurrentImage();
      await this.saveCurrentImage();
      this.pictureUri = await this.getDownloadURL();
    } else {
      // a picture is removed, and no new picture is set
      if (this.location !== undefined
        && this.pictureRef === ''
        && this.location.pictureRef !== undefined
        && this.location.pictureRef.length > 0) {
        await this.deleteCurrentImage();
      }
    }
  }

  async deleteCurrentImage() {
    if (this.location !== undefined && this.location.pictureRef !== undefined && this.location.pictureRef !== '') {
      await this.storage.ref(this.location.pictureRef).delete();
    }
  }

  async saveCurrentImage(): Promise<void> {
    this.pictureRef = `templatePics/${this.template.id}/${Date.now()}.png`;
    const file: File = this.base64ToFile(this.filedata.image, this.pictureRef);

    this.uploadTask = this.storage.upload(this.pictureRef, file);
    this.uploadStatus = this.uploadTask.percentageChanges();
    await this.uploadTask.snapshotChanges().pipe(
      tap (snap => {
        if (snap.bytesTransferred === snap.totalBytes) {
          this.pictureSize = snap.totalBytes;
        }
      })
    ).toPromise();
  }

  async getDownloadURL(): Promise<string> {
    return await this.storage.ref(this.pictureRef).getDownloadURL().toPromise();
  }

  base64ToFile(img: string, fileName: string): File {
    let byteString;
    if (img.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(img.split(',')[1]);
    } else {
        byteString = unescape(img.split(',')[1]);
    }

    const mimeString = img.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new File([new Blob([ia], {type: mimeString})], fileName, {type: 'image/png'});
  }

  revert(): void {
    this.form.reset();
    if (this.location !== undefined) {
      this.form.patchValue({...this.location});

      if (this.location.point !== undefined) {
        this.mapCenter = [this.location.point.longitude, this.location.point.latitude];
        this.markerPos = this.mapCenter;
      }
    }
  }

  openSite(): void {
    window.open(this.form.value.website, '_blank', 'height=800,width=400,menubar=no,status=no');
  }

  onMarkerDragEnd(marker: Marker) {
    NgZone.assertInAngularZone();
    this.mapCenter = marker.getLngLat().toArray();
    this.markerPos = this.mapCenter;
  }

  onZoom(event) {
    this.newZoomLevel = event.target.transform._zoom;
  }

  onMapMoveEnd(event) {
    const e = event as MapMouseEvent;
    this.newCenter = e.target.getCenter();
  }

  centerMarkerOnMap() {
    NgZone.assertInAngularZone();
    this.markerPos = [this.newCenter.lng, this.newCenter.lat ];
    this.mapCenter = this.markerPos;
  }

  showMarkerOnMap() {
    this.mapCenter = [0, 0];
    // map center must be different, but could be the same as the map center if the marker
    // was not moved.
    this.mapCenter = [this.markerPos[1] + 0.0000000000001, this.markerPos[0]];
  }

  removePicture() {
    this.pictureSize = 0;
    this.pictureRef = '';
    this.pictureUri = '';
    if (this.filedata.image !== undefined) {
      this.filedata.image = '';
    }
  }

  async cropImage(image: any): Promise<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      content: image,
      height: 40,
    };

    const dialogRef = this.dialog.open(ImageDialogComponent, dialogConfig);
    return await dialogRef.afterClosed().toPromise();
  }

  async fileChangeListener($event) {
    const image: any = new Image();
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = async (loadEvent: any) => {
        image.src = loadEvent.target.result;
        const result = await this.cropImage(image);
        if (result !== null) {
          this.imageChanged = true;
          this.filedata.image = result;
        }
    };

    myReader.readAsDataURL(file);
  }
}
