import { Template, compareTemplates } from './template.model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { TemplateActions } from './action-types';

export interface TemplateState extends EntityState<Template> {

}

export const adapter = createEntityAdapter<Template>({
    sortComparer: compareTemplates
});

export const initialTemplateState = adapter.getInitialState();

const templateReducer = createReducer(
    initialTemplateState,

    on(TemplateActions.added, (state, action) =>
        adapter.addOne(action.template, state)),

    on(TemplateActions.modified, (state, action) => adapter.updateOne({
        id: action.id,
        changes: action.template,
    }, state)),

    on(TemplateActions.removed, (state, action) =>
        adapter.removeOne(action.id, state)),

    on(TemplateActions.updateTemplate, (state, action) =>
        adapter.updateOne(action.update, state))
);

export const {
    selectAll
} = adapter.getSelectors();

export function reducer(state: TemplateState | undefined, action: Action) {
    return templateReducer(state, action);
}
