export const PriceRanges: Array<any> = [
    {value: 1, label: 'Very Cheap' },
    {value: 2, label: 'Cheap' },
    {value: 3, label: 'Average' },
    {value: 4, label: 'Expensive' },
    {value: 5, label: 'Very Expensive' },
];

export const Kitchens: Array<any>  = [
    {value: 1, label: 'American' },
    {value: 2, label: 'Chinese' },
    {value: 3, label: 'Eastern' },
    {value: 4, label: 'Fast Food' },
    {value: 5, label: 'Indian' },
    {value: 6, label: 'Japanese' },
    {value: 7, label: 'Local' },
    {value: 8, label: 'Pub Food' },
    {value: 9, label: 'Thai' },
    {value: 10, label: 'Other' },
];
