import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { reducer as authReducer, AuthState } from '../auth';
import { reducer as templateReducer, TemplateState } from '../templates/template.reducer';
import { reducer as presentationReducer, PresentationState } from '../presentation/presentation.reducer';
import { reducer as templateLocationReducer, TemplateLocationState } from '../templateLocations/templateLocation.reducer';
import { reducer as userReducer, CurrentUserState } from '../user/user.reducer';

// tslint:disable-next-line:no-empty-interface
export interface AppState {
    auth: AuthState;
    currentUser: CurrentUserState;
    router: RouterReducerState;
    templates: TemplateState;
    templateLocations: TemplateLocationState;
    presentation: PresentationState;
}

export const reducers: ActionReducerMap<AppState> = {
    auth: authReducer,
    router: routerReducer,
    templates: templateReducer,
    presentation: presentationReducer,
    templateLocations: templateLocationReducer,
    currentUser: userReducer,
};

