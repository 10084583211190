import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TemplateLocationState } from './templateLocation.reducer';
import * as fromTemplateLocations from './templateLocation.reducer';

export const selectTemplateLocationState = createFeatureSelector<TemplateLocationState>('templateLocations');

export const selectAllTemplateLocations = createSelector(
    selectTemplateLocationState,
    fromTemplateLocations.selectAll
);

export const selectTemplateLocationByID = (id) => createSelector(
    selectAllTemplateLocations,
    (locations) => locations[id]
);
