import { createAction, props } from '@ngrx/store';
import { Template } from './template.model';
import { Update } from '@ngrx/entity';

export const loadAllTemplates = createAction(
    '[Template Resolver] Load All Templates'
);

export const added = createAction(
    '[Template Effect] Template Added',
    props<{template: Template}>()
);

export const removed = createAction(
    '[Template Effect] Template Removed',
    props<{id: string}>()
);

export const modified = createAction(
    '[Template Effect] Template Modified',
    props<{id: string, template: Template}>()
);

export const updateTemplate = createAction(
    '[Template Editor] Update Template',
    props<{update: Update<Template>}>()
);

export const updateTemplateSuccess = createAction(
    '[Template Effect] Update Template Success'
);

export const createTemplate = createAction(
    '[Template Editor] Create Template',
    props<{template: Template}>()
);

export const createTemplateSuccess = createAction(
    '[Template Effect] Create Template Success'
);

export const deleteTemplate = createAction(
    '[Template Page] Delete Template',
    props<{templateID: string}>()
);

export const deleteTemplateSuccess = createAction(
    '[Template Effect] Delete Template Success'
);

