import { Component, OnInit } from '@angular/core';
import { AuthEffects } from 'src/app/state/auth';
import { AppState } from 'src/app/state/reducers';
import { Store } from '@ngrx/store';
import { AuthActions } from 'src/app/state/auth/action-types';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  returnUrl: string;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    // tslint:disable-next-line:no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  googleLogin() {
    this.store.dispatch(AuthActions.GoogleLogin({returnUrl: this.returnUrl}));
  }

}
