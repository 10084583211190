import { AuthState } from './auth.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const isLoggedIn = createSelector(
    selectAuthState,
    auth => !!auth.auth
);

export const currentAuth = createSelector(
    selectAuthState,
    auth => auth.auth
);
