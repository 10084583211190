import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppState } from '../reducers';
import { Store, select } from '@ngrx/store';
import { currentTemplate } from '../presentation/presentation.selector';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class TemplateGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.pipe(
            select(currentTemplate),
            tap(template => {
                if (template === undefined) {
                    this.router.navigate(['templates']);
                }
            }),
            map(template => true)
        );
    }
}
