import { firestore } from 'firebase';

export interface TemplateLocation {
    id: string;
    templateID: string;

    name: string;
    description: string;
    point: firestore.GeoPoint;

    website: string;
    entranceFee: boolean;
    priceRange: number;
    kitchen: string;

    pictureRef: string;
    pictureUri: string;
    pictureSize: number;
}

export function compareTemplateLocations(t1: TemplateLocation, t2: TemplateLocation) {
    return t1.name.localeCompare(t2.name);
}
