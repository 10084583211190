import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersPageComponent } from './views/users-page/users-page.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ProjectsPageComponent } from './views/projects-page/projects-page.component';
import { TemplatesPageComponent } from './views/templates-page/templates-page.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './state/auth/auth.guard';
import { TemplateResolver } from './state/templates/template.resolver';
import { TemplateEditorComponent } from './views/template-editor/template-editor.component';
import { TemplateLocationResolver } from './state/templateLocations/templateLocation.resolver';
import { TemplateGuard } from './state/templates/template.guard';
import { UserResolver } from './state/user/user.resolver';


const routes: Routes = [
  { path: 'users', component: UsersPageComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'projects', component: ProjectsPageComponent, canActivate: [AuthGuard] },
  {
    path: 'templates',
    component: TemplatesPageComponent,
    canActivate: [AuthGuard],
    resolve: {
      templates: TemplateResolver
    }
  },
  {
    path: 'template-editor',
    component: TemplateEditorComponent,
    canActivate: [AuthGuard],
    resolve: {
      templateLocations: TemplateLocationResolver,
      currentUser: UserResolver,
    }
  },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
