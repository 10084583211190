import { TemplateLocation, compareTemplateLocations } from './templateLocation.model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { LocationActions } from './action-types';

export interface TemplateLocationState extends EntityState<TemplateLocation> {

}

export const adapter = createEntityAdapter<TemplateLocation>({
    sortComparer: compareTemplateLocations
});

export const initialTemplateLocationState = adapter.getInitialState();

const templateLocationReducer = createReducer(
    initialTemplateLocationState,

    on(LocationActions.added, (state, action) =>
        adapter.addOne(action.location, state)),

    on(LocationActions.modified, (state, action) => adapter.updateOne({
        id: action.id,
        changes: action.location,
    }, state)),

    on(LocationActions.removed, (state, action) =>
        adapter.removeOne(action.id, state)),

    on(LocationActions.updateLocation, (state, action) =>
        adapter.updateOne(action.update, state)),

    on(LocationActions.loadAllLocations, (state, action) =>
        adapter.removeAll(state)),
);

export const {
    selectAll
} = adapter.getSelectors();

export function reducer(state: TemplateLocationState| undefined, action: Action) {
    return templateLocationReducer(state, action);
}
