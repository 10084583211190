import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppStateModule } from './state/state.module';
import { ViewModule } from './views/views.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { AppComponent } from './views/app/app.component';
import { ImageDialogComponent } from './dialogs/image-dialog/image-dialog.component';
import { DialogsModule } from './dialogs/dialogs.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppStateModule,
    MaterialModule,
    ViewModule,
    DialogsModule,
    BrowserAnimationsModule,
  ],
  entryComponents: [
    ImageDialogComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
