import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Template } from './template.model';

import * as fromTemplates from './template.reducer';
import { TemplateState } from './template.reducer';

export const selectTemplatesState = createFeatureSelector<TemplateState>('templates');

export const selectAllTemplates = createSelector(
    selectTemplatesState,
    fromTemplates.selectAll
);

export const selectPublishedTemplates = createSelector(
    selectAllTemplates,
    templates => templates.filter(template => template.published === true)
);

export const selectUnpublishedTemplates = createSelector(
    selectAllTemplates,
    templates => templates.filter(template => template.published === false)
);

export const selectTemplateByID = (id) => createSelector(
    selectAllTemplates,
    (templates) => templates[id]
);


