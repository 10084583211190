import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Template } from 'src/app/state/templates/template.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { Observable } from 'rxjs';
import { selectAllTemplates, selectPublishedTemplates, selectUnpublishedTemplates } from 'src/app/state/templates/template.selectors';
import { PresentationActions } from 'src/app/state/presentation/action-types';
import { Router } from '@angular/router';
import { Options } from 'simplebar-angular';
import { deleteTemplate } from 'src/app/state/templates/template.actions';

@Component({
  selector: 'app-templates-page',
  templateUrl: './templates-page.component.html',
  styleUrls: ['./templates-page.component.scss']
})
export class TemplatesPageComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['name'];

  selectedTemplate: Template = undefined;

  templates$: Observable<Template[]>;

  options: Options = {
    autoHide: false
  };

  private selection = 'all';
  public get Selection(): string { return this.selection; }
  public set Selection(selection: string) {
    this.selection = selection;
    this.reload();
  }

  constructor(
    private store: Store<AppState>,
    private router: Router
    ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    let selector = selectAllTemplates;
    if (this.selection === 'published') {
      selector = selectPublishedTemplates;
    } else if (this.selection === 'drafts') {
      selector = selectUnpublishedTemplates;
    }

    this.templates$ = this.store.pipe(
      select(selector)
    );
  }

  createNewTemplate() {
    this.store.dispatch(PresentationActions.SetCurrentTemplate(
      {template: undefined}
    ));
    console.log('open template editor');
    this.router.navigate(['template-editor']);
  }

  edit(template: Template) {
    this.store.dispatch(PresentationActions.SetCurrentTemplate({
      template
    }));
    this.router.navigate(['template-editor']);
  }

  delete(template: Template) {
    this.store.dispatch(deleteTemplate({templateID: template.id}));
  }
}
