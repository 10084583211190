import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserActions } from './action-types';
import { switchMap, map, catchError } from 'rxjs/operators';
import { User } from './user.model';
import { of } from 'rxjs';

@Injectable()
export class UserEffects {
    loadCurrentUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadCurrentUser),
        switchMap(action => {
           return this.db.collection('accounts')
           .doc<User>(action.id).valueChanges();
        }),
        map(action => {
            return UserActions.currentUserLoaded({
                user: action
            });
        }),
        catchError(error => {
           console.log('Error: ', error);
           return of(UserActions.currentUserLoadFailed({
              error
           }));
        })
    ));

    updateCurrentUserLocation$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.updateCurrentUserLocation),
        switchMap(data => {
            const ref = this.db.collection('accounts')
                .doc<User>(data.update.id.toString());
            return of(ref.update(data.update.changes));
        }),
        map(() => UserActions.updateCurrentUserSuccess())
    ));

    constructor(
        private actions$: Actions,
        private db: AngularFirestore
    ) {}
}
