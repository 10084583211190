import { AuthActions } from './action-types';
import { Auth } from './auth.model';
import { createReducer, on, Action } from '@ngrx/store';

export interface AuthState {
  auth: Auth;
  loading: boolean;
  error: string;
}

export const initialAuthState: AuthState = {
  auth: undefined,
  loading: false,
  error: ''
};

/**
 * Define all store queries for User
 */



// export const GetStoreUser = (state: AppState) => state.user;

const authReducer = createReducer(
    initialAuthState,

    on(AuthActions.GetAuthUser, (state, action) => {
        return {
            ...state,
            loading: true
        };
    }),

    on(AuthActions.Authenticated, (state, action) => {
        return {
            ...state,
            auth: action.auth,
            loading: false
        };
    }),

    on(AuthActions.NotAuthenticated, (state, action) => {
        return initialAuthState;
    }),

    on(AuthActions.GoogleLogin, (state, action) => {
        return {
            ...state,
            loading: true
        };
    }),

    on(AuthActions.AuthError, (state, action) => {
        return {
            ...state,
            error: action.error,
            loading: false
        };
    }),

    on(AuthActions.Logout, (state, action) => {
        return {
            ...state,
            loading: true
        };
    })

);

export function reducer(state: AuthState | undefined, action: Action) {
    return authReducer(state, action);
}
