import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements AfterViewInit {
  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  cropperSettings: CropperSettings;
  data: any;
  filedata: any;

  constructor(
    private dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.width = 100;
    this.cropperSettings.height = data.height;
    this.cropperSettings.croppedWidth = 500;
    this.cropperSettings.croppedHeight = data.height * 5;
    this.cropperSettings.canvasWidth = 400;
    this.cropperSettings.canvasHeight = 250;
    this.cropperSettings.minWidth = 1000;
    this.cropperSettings.minHeight = data.height * 10;
    this.cropperSettings.keepAspect = true;
    this.cropperSettings.fileType = 'image/png';
    this.data = data;
    this.filedata = {};
  }

  ngAfterViewInit() {
    this.cropper.setImage(this.data.content);
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  ok() {
    this.dialogRef.close(this.filedata.image);
  }

}
