import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { of } from 'rxjs';
import { defer } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';

import {Auth} from './auth.model';

import * as authActions from './auth.actions';
import { currentAuth as currentAuth } from './auth.selectors';
import { AppState } from '../reducers';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class AuthEffects {

    // ************************************************
    // Observable Queries available for consumption by views
    // ************************************************

    auth$ = this.store.select(currentAuth);
    returnUrl: string;

    // ************************************************
    // Effects to be registered at the Module level
    // ************************************************

    getAuth$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.GetAuthUser),
        switchMap(payload => this.afAuth.authState),
        map( authData => {
            if (authData) {
                /// User logged in
                const auth: Auth = {
                    uid: authData.uid,
                    displayName: authData.displayName,
                    photoURL: authData.photoURL,
                };
                return authActions.Authenticated({auth});
            } else {
                /// User not logged in
                return authActions.NotAuthenticated();
            }
        }),
        catchError(err => of(authActions.AuthError(err.message)))
    ));

    authenticated$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.Authenticated),
        tap(action => {
            if (this.returnUrl === undefined) {
                // tslint:disable-next-line:no-string-literal
                this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            }
            this.router.navigateByUrl(this.returnUrl);
        }),
    ), {dispatch: false});


    /**
     * Login with Google OAuth
     */
    login$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.GoogleLogin),
        switchMap(payload => {
            this.returnUrl = payload.returnUrl;
            return of( this.googleLogin() );
        }),
        map( credential => {
            return authActions.GetAuthUser();
        }),
        catchError(err => {
            return of(authActions.AuthError({error: err.message}));
        })
    ));


    logout$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.Logout),
        switchMap(payload => {
            return of(this.afAuth.auth.signOut() );
        }),
        map( authData => {
            this.router.navigateByUrl('/login');
            return authActions.NotAuthenticated();
        }),
        catchError(err => of(authActions.AuthError({error: err.message})))
    ));

    init$ = createEffect(() =>
        defer(() => {
        this.store.dispatch(authActions.GetAuthUser());
        }
    ), {dispatch: false});

    // ************************************************
    // Internal Code
    // ************************************************

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private afAuth: AngularFireAuth,
        private router: Router,
        private route: ActivatedRoute,
    ) {

    }


    // ******************************************
    // Internal Methods
    // ******************************************


    protected googleLogin(): Promise<any> {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.afAuth.auth.signInWithPopup(provider);
    }

}
