import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { UsersPageComponent } from './users-page/users-page.component';
import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { TemplatesPageComponent } from './templates-page/templates-page.component';
import { LeftBarComponent } from './left-bar/left-bar.component';
import { AppRoutingModule } from '../app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { ConnectFormDirective } from './connect-form.directive';
import { TemplateLocationListComponent } from './template-location-list/template-location-list.component';
import { TemplateLocationEditorComponent } from './template-location-editor/template-location-editor.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@NgModule({
    declarations: [
        UsersPageComponent,
        ProjectsPageComponent,
        TemplatesPageComponent,
        LeftBarComponent,
        DashboardComponent,
        LoginComponent,
        TemplateEditorComponent,
        ConnectFormDirective,
        TemplateLocationListComponent,
        TemplateLocationEditorComponent,
    ],
    imports: [
        MaterialModule,
        AppRoutingModule,
        NgxMapboxGLModule.withConfig({
            accessToken: 'pk.eyJ1IjoieXZhbnZkcyIsImEiOiJjaW8yeWlzZHkwMTB0dnNrbHg5Z2h1bG90In0.nkCOKHL02VXH-rRV0nNS1w'
        }),
    ],
    exports: [
        UsersPageComponent,
        ProjectsPageComponent,
        TemplatesPageComponent,
        LeftBarComponent,
        DashboardComponent,
        LoginComponent,
    ],

})
export class ViewModule { }
