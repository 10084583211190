import { createReducer, on, Action } from '@ngrx/store';
import { PresentationActions } from './action-types';
import { Template } from '../templates/template.model';

export interface PresentationState {
    currentTemplate: Template;
}

export const initialPresentationState: PresentationState = {
    currentTemplate: undefined,
};

const presentationReducer = createReducer(
    initialPresentationState,

    on(PresentationActions.SetCurrentTemplate, (state, action) => {
        return {
            ...state,
            currentTemplate: action.template
        };
    })
);

export function reducer(state: PresentationState | undefined, action: Action) {
    return presentationReducer(state, action);
}
