import { User } from './user.model';
import { createReducer, on, Action } from '@ngrx/store';
import { UserActions } from './action-types';

export interface CurrentUserState {
    userID: string;
    user: User;
    loading: boolean;
    error: string;
}

export const initialUserState: CurrentUserState =  {
    userID: '',
    user: undefined,
    loading: false,
    error: ''
};

const userReducer = createReducer(
    initialUserState,

    on(UserActions.loadCurrentUser, (state, action) => {
        return {
            ...state,
            userID: action.id,
            user: undefined,
            loading: true
        };
    }),

    on(UserActions.currentUserLoaded, (state, action) => {
        return {
            ...state,
            user: action.user,
            loading: false
        };
    }),

    on(UserActions.currentUserLoadFailed, (state, action) => {
        return {
            ...state,
            userID: '',
            user: undefined,
            error: action.error,
            loading: false
        };
    }),

    on(UserActions.updateCurrentUserLocation, (state, action) => {
        return {
            ...state,
            user: {
                ...state.user,
                ...action.update.changes,
            }
        };
    }),
);

export function reducer(state: CurrentUserState | undefined, action: Action) {
    return userReducer(state, action);
}
