import { createAction, props } from '@ngrx/store';
import { TemplateLocation } from './templateLocation.model';
import { Update } from '@ngrx/entity';

export const loadAllLocations = createAction(
    '[TemplateLocation Resolver] Load All TemplateLocations',
    props<{templateID: string}>()
);

export const added = createAction(
    '[TemplateLocation Effect] Location Added',
    props<{location: TemplateLocation}>()
);

export const removed = createAction(
    '[TemplateLocation Effect] Location Removed',
    props<{id: string}>()
);

export const modified = createAction(
    '[TemplateLocation Effect] Location Modified',
    props<{id: string, location: TemplateLocation}>()
);

export const updateLocation = createAction(
    '[Template Editor] Update Location',
    props<{templateID: string, update: Update<TemplateLocation>}>()
);

export const updateLocationSuccess = createAction(
    '[TemplateLocation Effect] Update Location Success'
);

export const createLocation = createAction(
    '[Template Editor] Create Location',
    props<{templateID: string, location: TemplateLocation}>()
);

export const createLocationSuccess = createAction(
    '[TemplateLocation Effect] Create Location Success'
);

export const deleteLocation = createAction(
    '[Template Editor] Delete Location',
    props<{templateID: string, locationID: string}>()
);

export const deleteLocationSuccess = createAction(
    '[TemplateLocation Effect] Delete Location Success'
);

