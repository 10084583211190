export interface Template {
    id: string;
    name: string;
    categoryName: string;
    description: string;
    published: boolean;

    enableWebsite: boolean;
    enablePriceRange: boolean;
    enableEntranceFee: boolean;
    enableKitchen: boolean;
    enablePicture: boolean;
    browsable: boolean;
}

export function compareTemplates(t1: Template, t2: Template) {
    return t1.name.localeCompare(t2.name);
}
