import { Component } from '@angular/core';
import { AuthEffects, Auth } from 'src/app/state/auth';
import { Observable } from 'rxjs';
import { StoreModule, Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { AuthActions } from 'src/app/state/auth/action-types';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  user$: Observable<Auth> = this.authService.auth$;

  constructor(
    private authService: AuthEffects,
    private store: Store<AppState>) {

  }

  logout() {
    this.store.dispatch(AuthActions.Logout());
  }
}
