import { createAction, props } from '@ngrx/store';
import { Auth } from './auth.model';

/// Get User AuthState

export const GetAuthUser = createAction(
    '[Auth] Get auth user',
);

export const Authenticated = createAction(
    '[Auth] Authenticated',
    props<{auth: Auth}>(),
);

export const NotAuthenticated = createAction(
    '[Auth] Not Authenticated',
);

export const AuthError = createAction(
    '[Auth] Error',
    props<{error: string}>(),
);

/// Google Login Actions

export const GoogleLogin = createAction(
    '[App Component] Google login attempt',
    props<{returnUrl: string}>(),
);

/// Logout Actions

export const Logout  = createAction(
    '[Top Menu] Logout'
);
