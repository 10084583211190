import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TemplateLocation } from 'src/app/state/templateLocations/templateLocation.model';
import { Observable } from 'rxjs';
import { Template } from 'src/app/state/templates/template.model';

@Component({
  selector: 'app-template-location-list',
  templateUrl: './template-location-list.component.html',
  styleUrls: ['./template-location-list.component.scss']
})
export class TemplateLocationListComponent implements OnInit {
  @Input() locations: Observable<TemplateLocation[]>;
  @Input() template: Observable<Template>;

  @Output() LocationSelect = new EventEmitter();
  @Output() LocationDelete = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  addLocation() {
    this.LocationSelect.emit();
  }

  editLocation(location: TemplateLocation) {
    this.LocationSelect.emit(location);
  }

  deleteLocation(location: TemplateLocation) {
    this.LocationDelete.emit(location.id);
  }
}
