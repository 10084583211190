import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers';
import { tap, first, finalize, map } from 'rxjs/operators';
import { loadAllLocations } from './templateLocation.actions';
import { currentTemplate } from '../presentation/presentation.selector';

@Injectable()
export class TemplateLocationResolver implements Resolve<any> {
    loading = false;

    constructor(private store: Store<AppState>) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.store.pipe(
            tap(() => {
                if (!this.loading) {
                    this.loading = true;
                    // get current template id from store
                    const template = this.store.pipe(
                        select(currentTemplate));
                    template.subscribe(
                        data => {
                            if (data !== undefined) {
                                this.store.dispatch(loadAllLocations({
                                    templateID: data.id}
                                ));
                            }
                        }
                    );
                }
            }),
            first(),
            finalize(() => this.loading = false)
        );
    }

}
