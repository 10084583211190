import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrentUserState } from './user.reducer';
import { AppState } from '../reducers';

export const selectUserState = (state: AppState) => state.currentUser;

export const currentUser = createSelector(
    selectUserState,
    (state: CurrentUserState) => state.user
);

export const currentUserID = createSelector(
    selectUserState,
    (state: CurrentUserState) => state.userID
);
