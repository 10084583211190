import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { LocationActions } from './action-types';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { TemplateLocation } from './templateLocation.model';
import { concatMap, map, mergeMap, switchMap, tap, distinctUntilChanged } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { TemplateActions } from '../templates/action-types';

@Injectable()
export class TemplateLocationEffects {
    loadAllLocations$ = createEffect(() => this.actions$.pipe(
        ofType(LocationActions.loadAllLocations),
        distinctUntilChanged(),
        tap(() => {

        }),
        map(action => action),
        switchMap(action => {
            return this.db.collection('templates')
            .doc(action.templateID)
            .collection<TemplateLocation>('locations').stateChanges();
        }),
        mergeMap(actions => actions),
        map(action => {
            switch (action.type) {
                case 'added': {
                    const data = action.payload.doc.data() as TemplateLocation;
                    data.id = action.payload.doc.id;
                    return LocationActions.added( {
                       location : data
                    });
                }

                case 'removed':
                    return LocationActions.removed( {id: action.payload.doc.id });

                case 'modified': {
                    const data = action.payload.doc.data() as TemplateLocation;
                    data.id = action.payload.doc.id;

                    return LocationActions.modified( {
                        id: action.payload.doc.id,
                        location: data
                    });
                }
            }
        })
    ));

    updateLocation$ = createEffect(() => this.actions$.pipe(
        ofType(LocationActions.updateLocation),
        switchMap(data => {
            const ref = this.db.collection('templates')
                .doc(data.templateID)
                .collection('locations')
                .doc<TemplateLocation>(data.update.id.toString());
            return of(ref.update(data.update.changes));
        }),
        map(() => LocationActions.updateLocationSuccess())
    ));

    createLocation$ = createEffect(() => this.actions$.pipe(
        ofType(LocationActions.createLocation),
        switchMap(data => {
            console.log('create location: ' + data.templateID);
            const ref = this.db.collection('templates')
                .doc(data.templateID)
                .collection('locations');
            return of(ref.add(data.location));
        }),
        map(() => LocationActions.createLocationSuccess())
    ));

    deleteLocation$ = createEffect(() => this.actions$.pipe(
        ofType(LocationActions.deleteLocation),
        switchMap(data => {
            const ref = this.db.collection('templates')
                .doc(data.templateID)
                .collection('locations').doc(data.locationID);
            return of(ref.delete());
        }),
        map(() => LocationActions.deleteLocationSuccess())
    ));

    private subscription: Observable<DocumentChangeAction<TemplateLocation>[]> = null;

    constructor(
        private actions$: Actions,
        private db: AngularFirestore
    ) {
    }
}
